import classNames from "classnames"
import React from "react"

import { Color } from "../../../constants/V2/color"

import { getBackgroundColorClass } from "@utils/V2/color"

type Props = {
  className?: string
  backgroundColor: Color
  height: number
}

const SpacerSection = ({
  className,
  backgroundColor,
  height,
  ...props
}: Props) => {
  return (
    <div
      style={{ height: `${height}px` }}
      className={classNames(
        "w-full",
        getBackgroundColorClass(backgroundColor),
        className
      )}
      {...props}
    />
  )
}

export default SpacerSection
